import React from 'react'
import './TimeLine2.css'

const TimeLine = ({ id, stats, buttonName }) => (
  <section id="timeline1" class="pt-5 pr-5 pl-5">
    <div className="otm-home1 traveltime">
      <div className="row">
        <div className="col-sm-12">
          <p className="pag-line-trust">EVENT TIMELINE</p>
          <div className="wrap">
            <div className="top">
              <div className="item">
                <div className="top-txt">2024</div>
              </div>
            </div>
            <div className="line" />
            <div className="bottom">
              <div className="placeholder" />            
              <div className="item green">
                <div className="timelineheding ">BLTM</div>
                <div className="timelinetxt">29, 30 & 31 August</div>
              </div>             
              <div className="item">
                <div className="timelineheding">TTF Patna</div>
                <div className="timelinetxt">20 & 21 September</div>
              </div>
            </div>
          </div>
          <div className="wrap1">
            <div className="top">
              <div className="item">
                <div className="top-txt2">2025</div>
              </div>
            </div>
            <div className="line1" />
            <div className="bottom1"> 
              <div className="item1 pink">
                <div className="timelineheding">OTM</div>
                <div className="timelinetxt">30, 31 January & 1 February</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Bengaluru</div>
                <div className="timelinetxt">13, 14 & 15 February</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Chennai</div>
                <div className="timelinetxt">21, 22 & 23 March</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Kolkata</div>
                <div className="timelinetxt">11, 12 & 13 July</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Ahmedabad</div>
                <div className="timelinetxt">31 July, 1 & 2 August</div>
              </div>
              {/* 
              <div className="item1">
                <div className="timelineheding">TTF SOUTH (Hyderabad)</div>
                <div className="timelinetxt">10, 11 July</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Ahmedabad</div>
                <div className="timelinetxt">04, 05, 06 September</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Surat</div>
                <div className="timelinetxt">11, 12, 13 September</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Mumbai</div>
                <div className="timelinetxt">18, 19, 20 September</div>
              </div>
              <div className="item1">
                <div className="timelineheding">TTF Pune</div>
                <div className="timelinetxt">25, 26, 27 September</div>
              </div> */}
              <div className="placeholder1" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TimeLine
